.access-denied-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.access-denied-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.access-denied-title {
  font-size: 48px;
  font-weight: bold;
  color: #01bf71;
  margin-bottom: 16px;
}

.access-denied-message {
  font-size: 18px;
  color: #777;
  text-align: center;
  margin-bottom: 24px;
}

.access-denied-back-button {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #01bf71;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.access-denied-back-button:hover {
  background-color: #e52929;
}
