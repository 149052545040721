/* form {
  max-width: 800px;
  margin: 0 auto;
} */

/* h1 {
  margin-top: 80px;
  font-weight: 100;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
} */

/* .form {
  background: #0e101c;
  max-width: 400px;
  margin: 0 auto;
} */

/* button[type="button"] {
  background: black;
  border: 1px solid white;
} */

/* p {
  color: #bf1650;
  text-align: center;
} */

input.contact {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}
select.contact-select {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.materialUIInput {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  background-color: white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.IceCreamType {
  color: black;
}

label.contact-label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

button.contact-submit,
input[type="submit"].contact-submit {
  background: #20232a;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

button.button-submit {
  display: block;
  appearance: none;
  border-radius: 4px;
  width: 100%;
}

input[type="submit"]:hover {
  background: grey;
}

input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

/* form {
  margin-bottom: 400px;
} */

/* .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
} */

/* .counter {
  font-weight: 400;
  background: white;
  color: black;
  padding: 10px 15px;
  border-radius: 4px;
  position: fixed;
  top: 20px;
  right: 20px;
} */

@media only screen and (max-width: 1026px) {
  div.hide {
    display: none;
  }
}
